<template>
    <div>
        <Loading v-show="isLoading" />

        <div v-show="!isLoading">

            <!-- Toolbar -->    
            <TitlePage 
                :title="'EDITAR ' + user.last_name + ' ' + user.first_name"
                :id="user.id"
                back="/users" 
                :reset="false"
            />

            <!-- Form --> 
            <div class="field col-12 mt-1">
                <div class="col text-right">
                    <Btn 
                        @click="resetPwd()"
                        label="RESET CLAVE"
                        icon='fas fa-key'
                        classBtn='p-button-danger p-button-outlined'
                    />
                </div>
            </div>

            <div class="col-12 card mt-3">
                <form @submit.prevent="updateUser">
                    <div class="formgrid grid">

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_last_name" 
                                    ref="user_last_name" 
                                    v-model="user.last_name" 
                                    autofocus tabindex="1" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_last_name">Apellido <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_first_name" 
                                    ref="user_first_name" 
                                    v-model="user.first_name" 
                                    tabindex="2" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_first_name">Nombre <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_phone" 
                                    ref="user_phone" 
                                    v-model="user.phone_number" 
                                    tabindex="3" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_phone">Teléfono</label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_email" 
                                    ref="user_email" 
                                    v-model="user.email" 
                                    tabindex="4" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_email">Email <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_address" 
                                    ref="user_address" 
                                    v-model="user.address" 
                                    tabindex="4" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_address">Dirección</label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_username" 
                                    ref="user_username" 
                                    v-model="user.username" 
                                    tabindex="5" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_username">Usuario <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <Dropdown 
                                id="user_state" 
                                ref="user_state" 
                                v-model="user.state" 
                                :options="defaultData.comboStates" 
                                optionLabel="label" 
                                optionValue="value"
                                placeholder="Estado" 
                                class="w-full"
                                tabindex="6"
                            />
                        </div>

                        <div class="field col-12 md:col-6 lg:col-6 pt-3">
                            <div class="p-inputgroup">
                                <MultiSelect 
                                    id="user_role" 
                                    ref="user_role" 
                                    v-model="user.roles" 
                                    :options="roles" 
                                    optionLabel="name" 
                                    optionValue="id"
                                    placeholder="Seleccionar Roles *" 
                                    class="w-full"
                                    display="chip"
                                    tabindex="7"
                                />
                            </div>
                        </div>
                
                        <div class="col-12 my-5">
                            <span class="text-bluegray-300">Campos requeridos *</span>
                            <p class="font-semibold" v-if="password != ''">Se asignó la clave: <span class="font-bold bg-primary p-1 border-round">{{password}}</span></p>
                        </div>
                    </div>
                
                    <div class="grid">
                        <div class="col-12 text-center">
                            <Button
                                label="ACTUALIZAR"
                                tabindex="8"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <MissingRecord v-if="missingRecord"/>
    </div>
</template>

<script>

import router from '../../router'
import apiService from '../../services/apiService.js'


export default {
    name: 'NewUser',

    data(){
        return {
            isLoading: false,
            user: {
                id: '',
                username: '',
                password: '',
                last_name: '',
                first_name: '',
                phone_number: '',
                email: '',
                address: '',
                state: 1,
                roles: []
            },
            roles: [],
            missingRecord: false,
            password: ''
        }
    },
    mounted() {
        this.getUser()
        this.getRoles()
    },
    methods: {
        async getUser(){
            this.isLoading = true
            apiService.User.get(this.$route.params.id)
            .then((result) => {

                this.user.id = result[0].id;
                this.user.username = result[0].username;
                this.user.last_name = result[0].last_name;
                this.user.first_name = result[0].first_name;
                this.user.phone_number = result[0].phone_number;
                this.user.email = result[0].email;
                this.user.address = result[0].address;
                this.user.state = result[0].state;
                this.user.roles = result[0].roles.map(x => x['id']);

                this.isLoading = false

            }).catch(() => {
                this.missingRecord = true
            })
        },
        async getRoles(){
            apiService.Role.get()
            .then((result) => {
                this.roles = result;
                this.isLoading = false
            })
        },
        async updateUser(){
            var form = this.globalMethods.getFormData(this.user);
            this.isLoading = true
            apiService.User.update(form)
            .then((result) => {
                if(result.status == 'success'){
                    this.FormSuccess(result.message)
                    this.isLoading = false
                    router.push("/users");
                }else{
                    this.FormErrors(result)
                    this.isLoading = false
                }
            }).catch((e) => {
                this.FormRequestFail(e);
                this.isLoading = false
            })
        },
        async resetPwd(){
            this.$confirm.require({
                message: 'Está seguro de que desea continuar?.',
                header: 'Confirmación',
                icon: 'fas fa-key',
                accept: () => {
                    this.isLoading = true
                    apiService.User.resetPwd(this.$route.params.id)
                    .then((result) => {
                        if(result.status == 'success'){
                            this.FormSuccess(result.message)
                            this.password = result.password
                            this.isLoading = false
                        }else{
                            this.FormErrors(result)
                            this.isLoading = false
                        }
                    }).catch((e) => {
                        this.FormRequestFail(e);
                        this.isLoading = false
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        }
    }
}
</script>